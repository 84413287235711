import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Banner from '../components/Banner'

import hoikupedia from '../assets/images/hoikupedia.jpg'
import wheel from '../assets/images/wheel.png'
import sozai from '../assets/images/sozai.jpg'
import imdb from '../assets/images/imdb-clone.jpg'
import uidesign from '../assets/images/uidesign.jpg'

class HomeIndex extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet
          title="Tae Kimura - Portfolio"
          meta={[
            { name: 'description', content: 'Tae Kimura- Portfolio' },
            { name: 'keywords', content: 'Tae Kimura- Portfolio' },
          ]}
        ></Helmet>

        <Banner />
        <div id="main">
          <section id="projects" className="spotlights">
            <section>
              <Link to="/sozai" className="image">
                <img src={sozai} alt="" />
              </Link>
              <div className="content">
                <div className="inner fadein">
                  <header className="major">
                    <p>Case study 1 </p>
                    <br />
                    <h2> E-commerce Website</h2>
                  </header>
                  <p>
                    Large Next.js project for a fictional plant store with user
                    login, cart management, stripe payments, and a management
                    console.
                  </p>
                  <br />
                  <div class="wrap">
                    <span class="sc-fznJRM bTIjTR">Next.js</span>
                    <span class="sc-fznJRM bTIjTR">Typescript</span>
                    <span class="sc-fznJRM bTIjTR">Tailwind CSS</span>
                    <span class="sc-fznJRM bTIjTR">MongoDB</span>
                    <span class="sc-fznJRM bTIjTR">Firebase</span>
                    <span class="sc-fznJRM bTIjTR">Stripe</span>
                  </div>
                  <br />
                  <a className="link" href="/sozai">
                    View details{' '}
                  </a>
                  <a
                    className="link"
                    href="https://nextjs-plant-tae-ecommerce.vercel.app/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Live demo{' '}
                  </a>
                  <br />
                  <br />
                </div>
              </div>
            </section>
            <section>
              <Link to="/hoikupedia" className="image">
                <img src={hoikupedia} alt="" />
              </Link>
              <div className="content">
                <div className="inner fadein">
                  <header className="major">
                    <p>Case study 2 </p>
                    <br />
                    <h2>Hoikupedia Website</h2>
                  </header>
                  <p>
                    "Hoikupedia" is the website provides information for
                    japanese applicants who want to work in the field of Early
                    Child Care.
                    <br />
                    Developed a custom WordPress theme from scratch using PHP,
                    HTML, SCSS, jQuery with TypeScript.
                  </p>
                  <br />
                  <span class="sc-fznJRM bTIjTR">WordPress</span>
                  <span class="sc-fznJRM bTIjTR">PHP</span>
                  <span class="sc-fznJRM bTIjTR">TypeScript</span>
                  <span class="sc-fznJRM bTIjTR">jQuery</span>
                  <br /> <br />
                  <div className="link-container">
                    <a className="link" href="/hoikupedia">
                      View details{' '}
                    </a>
                    <a
                      className="link"
                      href="https://hoikupedia.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Visit Website{' '}
                    </a>
                  </div>
                  <br />
                  <br />
                </div>
              </div>
            </section>
            <section>
              <Link to="/wheel" className="image">
                <img src={wheel} alt="" />
              </Link>
              <div className="content">
                <div className="inner fadein">
                  <header className="major">
                    <p>Case study 3 </p>
                    <br />
                    <h2>Wheel of Prosperity Project</h2>
                  </header>
                  <p>
                    This project consists of the creation of a 24-question quiz,
                    based on an answer ranging from 0 to 10. <br />
                    The result will be accompanied by a "wheel of prosperity"
                    visual that will show the curve of the results.
                  </p>
                  <br />
                  <span class="sc-fznJRM bTIjTR">React</span>
                  <span class="sc-fznJRM bTIjTR">Redux</span>
                  <span class="sc-fznJRM bTIjTR">React-i18next</span>
                  <span class="sc-fznJRM bTIjTR">Firebase</span>
                  <br />
                  <br />
                  <a className="link" href="/wheel">
                    View details{' '}
                  </a>
                  <a
                    className="link"
                    href="https://wheel-of-prosperity-portfolio.netlify.app/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Live demo{' '}
                  </a>
                  <br />
                  <br />
                </div>
              </div>
            </section>
            <section>
              {/* <Link to="/" className="image"> */}
              <img className="image" src={imdb} alt="" />
              {/* </Link> */}
              <div className="content">
                <div className="inner fadein">
                  <header className="major">
                    <p>Case study 4</p>
                    <br />
                    <h2>IMDb Clone</h2>
                  </header>
                  <p>IMDb clone using Next.js, Typescript, and Tailwind CSS</p>
                  <br />
                  <span class="sc-fznJRM bTIjTR">Next.js</span>
                  <span class="sc-fznJRM bTIjTR">TypeScript</span>
                  <span class="sc-fznJRM bTIjTR">Tailwind CSS</span>
                  <br /> <br />
                  <div className="link-container">
                    <a
                      className="link"
                      href="https://github.com/taekimura/nextjs-imdb-clone"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Go to github{' '}
                    </a>
                    <a
                      className="link"
                      href="https://nextjs-imdb-clone-tae.vercel.app/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Live demo{' '}
                    </a>
                  </div>
                  <br />
                  <br />
                </div>
              </div>
            </section>
            <section>
              <Link to="/" className="image">
                <img src={uidesign} alt="" />
              </Link>
              <div className="content">
                <div className="inner fadein">
                  <header className="major">
                    <p>Case study 5</p>
                    <br />
                    <h2>UI Design Challenge</h2>
                  </header>
                  <p>
                    UI design challenge to understand compelling designs and
                    improve my design skill.
                  </p>
                  <br />
                  <span class="sc-fznJRM bTIjTR">Illustrator</span>
                  <span class="sc-fznJRM bTIjTR">Photoshop</span>
                  <span class="sc-fznJRM bTIjTR">XD</span>
                  <br /> <br />
                  <a
                    className="link"
                    href="https://dribbble.com/OTAE"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View project{' '}
                  </a>
                </div>
              </div>
            </section>
          </section>
        </div>

        <div id="main">
          <section id="projects">
            <div className="inner">
              <header className="major">
                <h1>My Skills</h1>
              </header>
              <p className="fadein setumei">
                Through my work experience, I've gained a understanding of web
                development concepts, and have dedicated a lot of my free time
                to apply these concepts to real-world scenarios and
                applications.
              </p>
              <br />
              <div className="fadein pages__SkillTable-sc-6kvjaa-1 cgIyGY">
                <row>
                  <p class="Paragraph-sc-1tzuhix-0 cjRxGx">
                    <span className="maru">●</span> Javascript
                  </p>
                  <p class="Paragraph-sc-1tzuhix-0 cjRxGx">
                    <span className="maru">●</span> PHP
                  </p>
                </row>
                <row>
                  <p class="Paragraph-sc-1tzuhix-0 cjRxGx">
                    <span className="maru">●</span> React
                  </p>
                  <p class="Paragraph-sc-1tzuhix-0 cjRxGx">
                    <span className="maru">●</span> HTML &amp; CSS
                  </p>
                </row>
                <row>
                  <p class="Paragraph-sc-1tzuhix-0 cjRxGx">
                    <span className="maru">●</span> Next.js
                  </p>
                  <p class="Paragraph-sc-1tzuhix-0 cjRxGx">
                    <span className="maru">●</span> Sass
                  </p>
                </row>
                <row>
                  <p class="Paragraph-sc-1tzuhix-0 cjRxGx">
                    <span className="maru">●</span> TypeScript
                  </p>
                  <p class="Paragraph-sc-1tzuhix-0 cjRxGx">
                    <span className="maru">●</span> WordPress
                  </p>
                </row>
              </div>
              <br />
              <br />
              <br />
              <br />
              <ul className="actions">
                <li className="centeryose">
                  <Link to="/about" className="button next" id="centeryose">
                    Learn more about me
                  </Link>
                </li>
              </ul>

              <br />
              <br />
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default HomeIndex

import React from 'react'
import { Link } from 'gatsby'
import Slide from 'react-reveal/Slide'

const Banner = props => {
  const setFillHeight = () => {
    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }

  React.useEffect(() => {
    let vw = window.innerWidth
    let vh = window.innerHeight

    const handleResize = () => {
      if (vw === window.innerWidth && vh === window.innerHeight) {
        // 画面の横幅・縦幅にサイズ変動がないので処理を終える
        return
      }

      // 画面の横幅または縦幅のサイズ変動があった時のみ高さを再計算する
      vw = window.innerWidth
      vh = window.innerHeight
      setFillHeight()
    }

    setFillHeight()

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <section id="banner" className="major">
      <>
        <div className="inner-first">
          <Slide>
            <h1 className="namae">I am Tae Kimura,</h1>
            <h1 className="namae">Frontend developer based in Vancouver,CA.</h1>
          </Slide>
          <br />
          <div className="content">
            <p className="uri">
              building compelling websites,
              <br />
              loves to learn new technologies.
            </p>
          </div>
          <ul id="icons">
            <li>
              <a
                href="https://www.linkedin.com/in/taekimura/"
                className="icon alt fa-linkedin fa-lg"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="label">LinkedIn</span>
              </a>
            </li>
            <li>
              <a
                href="https://github.com/taekimura"
                className="icon alt fa-github fa-lg"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="label">GitHub</span>
              </a>
            </li>
            <li>
              <a
                href="https://dribbble.com/OTAE"
                className="icon alt fa-dribbble fa-lg"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="label">Dribbble</span>
              </a>
            </li>
            {/* <li>
            <a
              href={resume}
              className="icon alt fa-file fa-lg"
              onclick="gtag('event', 'download', {'event_category': 'pdf','event_label': 'resume_download','value': '1'});"
            >
              <span className="label">Resume</span>
            </a>
          </li> */}
            <li>
              <a
                href="mailto:sheepman7893@gmail.com"
                className="icon alt fa-envelope fa-lg"
              >
                <span className="label">mail</span>
              </a>
            </li>
          </ul>
          <br />
        </div>
        <Link to="/#projects">
          <a className="scroll">
            <span></span>
          </a>
        </Link>
      </>
    </section>
  )
}

export default Banner
